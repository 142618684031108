import React, { FunctionComponent } from "react";
import styled from "styled-components";
import colors from "../palette";
import Section from "./Section";
import alpha from "../assets/alphaart.svg";
import magic from "../assets/magiceden.svg";
import ftx from "../assets/ftx.png";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 32px 32px;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-width: 100%;
`;

const Box = styled.a`
  padding: 16px;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  background: ${colors.secondary};
  color: black;
  font-weight: 700;
  border-radius: 32px;
  box-sizing: border-box;
  margin: 8px;
  display: flex;
  align-items: center;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.3));

  & > img {
    max-height: 64px;
  }
`;

const Callouts: FunctionComponent = () => {
  return (
    <Section>
      <h2 style={{ width: "100%", flexBasis: "100%", textAlign: "center" }}>
        ACQUIRE SCOOGIS
      </h2>
      <Wrapper>
        <Box
          href="https://www.alpha.art/collection/scoogis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={alpha} alt="Alpha Art" />
        </Box>
        <Box
          href="https://www.magiceden.io/marketplace/scoogis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={magic} alt="Magic Edgen" />
        </Box>
        <Box
          href="https://ftx.us/nfts/collection/Scoogis/25/1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ftx} alt="FTX NFT" />
        </Box>
      </Wrapper>
    </Section>
  );
};

export default Callouts;
