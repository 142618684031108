import React, { FunctionComponent } from "react";
import styled from "styled-components";
import colors from "../palette";
import roadmap from "../roadmap";
import Section from "./Section";

const Wrapper = styled.div`
  text-align: center;
  padding-bottom: 32px;
`;

const List = styled.div``;

const ListItem = styled.div<{ complete: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  filter: drop-shadow(4px 4px 5px rgba(31, 32, 65, 0.3));

  ::before {
    content: "";
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: ${({ complete }) => (complete ? colors.tertiary : colors.dark)};
    margin-right: 16px;
    z-index: 1;
  }
  ::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 2px;
    background: ${colors.secondary};
    left: 31px;
  }
`;

const ItemContent = styled.div<{ complete: boolean }>`
  padding: 16px;
  background: ${({ complete }) => (complete ? colors.tertiary : colors.dark)};
  color: black;
  flex: 1;
  border-radius: 32px;
  margin: 8px 0;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 16px;
  }
`;

const Roadmap: FunctionComponent = () => {
  return (
    <Section
      color={`linear-gradient(180deg, ${colors.base} 0%, ${colors.alternate} 100%)`}
    >
      <Wrapper>
        <h2>ROADMAP</h2>
        <List>
          {roadmap.map((i) => (
            <ListItem key={i.title} complete={i.complete}>
              <ItemContent complete={i.complete}>
                <Title>
                  {i.icon}
                  <h3>{i.title}</h3>
                </Title>
                {i.description && <p>{i.description}</p>}
              </ItemContent>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    </Section>
  );
};

export default Roadmap;
