import {
  FaFlagCheckered,
  FaGem,
  FaHandHoldingHeart,
  FaRocket,
  FaVoteYea,
} from "react-icons/fa";

const roadmap = [
  {
    title: "Launch ScoogiDAO",
    complete: true,
    icon: <FaRocket size={32} />,
  },
  {
    title: "Member acquisition",
    complete: true,
    icon: <FaHandHoldingHeart size={32} />,
  },
  {
    title: "Introduce multisig treasury",
    complete: false,
    icon: <FaGem size={32} />,
  },
  {
    title: "Voting & governance",
    complete: false,
    icon: <FaVoteYea size={32} />,
  },
  {
    title: "Launch Project X",
    complete: false,
    icon: <FaFlagCheckered size={32} />,
    description:
      "Project X is how the DAO will generate its revenue. We have a general goal in mind but the implementation will be decided by members of ScoogiDAO",
  },
];

export default roadmap;
