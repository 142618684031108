import React, { FunctionComponent } from "react";
import styled from "styled-components";
import logo from "../assets/scoogi-logo.png";
import Section from "./Section";
import background from "../assets/background.jpg";
import colors from "../palette";

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 85px;

  & > * {
    max-width: 500px;
    width: 100%;
  }
`;

const Box = styled.a`
  padding: 16px;
  box-sizing: border-box;
  color: white;
  text-decoration: none;
  background: ${colors.alternate};
  color: black;
  font-weight: 700;
  border-radius: 16px;
  margin-bottom: 32px;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.3));
`;

const Title: FunctionComponent = () => {
  return (
    <Section background={background}>
      <Wrapper>
        <h2>
          ScoogiDAO: We mean serious business, but we don't take ourselves too
          seriously.
        </h2>
        <p>
          We are a community of artists, developers, marketers, content
          creators, and investors united by our love of crypto and cartoon
          frogs.
        </p>
        <p>
          Membership in ScoogiDAO is exclusively available to those who hold
          Scoogis NFTs.
        </p>
        <img src={logo} alt="logo" width="500px" />
        <Box
          href="https://discord.gg/vYQtvfxuBF"
          target="_blank"
          rel="noopener noreferer"
        >
          JOIN SCOOGI DAO
        </Box>
      </Wrapper>
    </Section>
  );
};

export default Title;
