import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import colors from "../palette";

const Wrapper = styled.div<{ background?: string; color?: string }>`
  display: flex;
  justify-content: center;
  background: ${({ color }) => color || colors.base};
  width: 100%;
  ${({ background }) =>
    background &&
    css`
      background: transparent;
      ::before {
        content: "";
        left: 0;
        width: 100%;
        position: fixed;
        background-image: url(${background});
        opacity: 0.5;
        height: 100%;
        z-index: -1;
      }
    `}
`;

const Content = styled.div`
  max-width: 860px;
  flex: 1;
  padding: 0 16px;
`;

interface Props {
  background?: string;
  color?: string;
}

const Section: FunctionComponent<Props> = ({ children, background, color }) => {
  return (
    <Wrapper background={background} color={color}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Section;
