import React from "react";
import Callouts from "./components/Callouts";
import Footer from "./components/Footer";
import GlobalStyle from "./components/GlobalStyle";
import Header from "./components/Header";
import Layout from "./components/Layout";
import Roadmap from "./components/Roadmap";
import Title from "./components/Title";

function App() {
  return (
    <>
      <GlobalStyle />
      <Layout>
        <Header />
        <Title />
        <Callouts />
        <Roadmap />
        <div style={{ flex: 1 }} />
        <Footer />
      </Layout>
    </>
  );
}

export default App;
