import React, { FunctionComponent } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
`;

const Layout: FunctionComponent = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Layout;
