import React, { FunctionComponent } from "react";
import styled from "styled-components";
import colors from "../palette";
import Section from "./Section";

const Wrapper = styled.footer`
  padding: 16px;
  text-align: center;
  color: ${colors.dark};
`;

const Footer: FunctionComponent = () => {
  return (
    <Section color={colors.alternate}>
      <Wrapper>
        ScoogiDAO is a community-run initiative, and is not directly affiliated
        nor under the management of Scoogis
      </Wrapper>
    </Section>
  );
};

export default Footer;
