import React, { FunctionComponent } from "react";
import styled from "styled-components";
import colors from "../palette";
import discordIcon from "../assets/discord.svg";
import twitterIcon from "../assets/twitter.svg";

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  background: ${colors.base};
  box-shadow: 1px 1px 0 ${colors.secondary};
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 16px;
  box-sizing: border-box;

  h1 {
    color: ${colors.alternate};
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 860px;
  flex: 1;
`;

const Socials = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled.a`
  padding: 16px;
  color: white;
  text-decoration: none;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.3));
`;

const Header: FunctionComponent = () => {
  return (
    <Wrapper>
      <Content>
        <h1>ScoogiDAO</h1>
        <Socials>
          <Link
            href="https://discord.gg/vYQtvfxuBF"
            target="_blank"
            rel="noopener noreferer"
          >
            <img src={discordIcon} alt="Discord" height="32px" width="auto" />
          </Link>
          <Link
            href="https://twitter.com/scoogidao"
            target="_blank"
            rel="noopener noreferer"
          >
            <img src={twitterIcon} alt="Twitter" height="32px" width="auto" />
          </Link>
        </Socials>
      </Content>
    </Wrapper>
  );
};

export default Header;
