import { createGlobalStyle } from "styled-components";
import colors from "../palette";

const GlobalStyle = createGlobalStyle`

    html, body {
        display: block;
        box-sizing: border-box;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        margin: 0;
        padding: 0;
    }

    body {
        background: ${colors.base};
        color: whitesmoke;
        height: 100vh;
        width: 100%;
    }
`;

export default GlobalStyle;
